:root {
    --background-grey: #ecebef;
    --topbar-dark: #293039;
    --darker: #212529;
    --light-green-1: #85ce36;
    --green-2: #12d612;
    --sidebar-gradient-1: rgba(99, 99, 99, 0.85);
    --sidebar-gradient-2: rgba(18, 214, 18, 0.5);

    --top-margin: 9.2%;
    --top-margin-850px: 10%;
    --top-margin-1000px: 11.5%;
    --top-margin-600px: 13.8%;

    --full-sidebar-width: 15%;
    --hidden-sidebar-width: 7.5%;
    --normal-content-width: 85%;
    --extended-content-width: 92.5%;
}

body {
    overflow-x: hidden;
}

.App {
    width: 100%;
    height: 100%;
    
    display: flex;
    flex-flow: row nowrap;
    justify-content: stretch;
    align-items: stretch;

    overflow-x: hidden;
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
}

.MainApp {
    width: 100%;
    height: 100%;

    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: stretch;

    background-color: var(--background-grey);
    overflow-x: hidden;
}

#content-row {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: stretch;

    margin-top: var(--top-margin);
}

#sidebar-col {
    transition: 0.5s;
    background-image: linear-gradient(var(--sidebar-gradient-1), var(--sidebar-gradient-2), var(--sidebar-gradient-1));
}

.sidebar-col-full#sidebar-col {
    width: var(--full-sidebar-width);
    min-width: 205px;
}

.sidebar-col-hidden#sidebar-col {
    width: var(--hidden-sidebar-width);
    min-width: 60px;
}

#content-col {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    align-items: stretch;

    min-height: 81vh;
    transition: 0.5s;
}

.content-col-normal#content-col {
    width: var(--normal-content-width);
}

.content-col-extended#content-col {
    width: var(--extended-content-width);
}

::-webkit-scrollbar {
    min-width: 10px;
    width: 1%;
    background-color: var(--topbar-dark);
}

::-webkit-scrollbar-thumb {
    background-image: linear-gradient(var(--green-2), var(--light-green-1));
    border-radius: 25px;
}

::-webkit-scrollbar-corner {
    background-color: var(--topbar-dark);
}

@media screen and (max-width: 1000px) {
    #content-row {
        margin-top: var(--top-margin-1000px);
    }
}

@media screen and (max-width: 600px) {
    #content-row {
        margin-top: var(--top-margin-600px);
    }
}

@media (max-width: 850px) and (orientation: landscape) {

    #content-row {
        margin-top: 0;
    }

    #content {
        margin-top: 7.5%;
    }
    
    #nav-logo {
        height: 13.5vh;
    }

    #top-nav {
       width: 100%;
       display: flex;
       flex-direction: row nowrap;
       height: auto;
    }

    #profile-pic {
        width: 2.35em;
        min-width: unset;
        margin: -0.8em 0 0 0;
    }
}