.login-input-icon {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;

    width: 50px;
    margin: 0px;
    margin-right: -1px;

    color: var(--light-green-1);
    background-color: transparent;

    border: 1px solid var(--light-green-1);
    border-radius: .25rem;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.login-errors {
    color: rgb(230, 0, 0);
}

#login-check-submit-row {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
}

#login-memorize {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
}

#login-memorize-checkbox {

}

#login-memorize-checkbox:checked {
    
}

#login-memorize-text {
    padding-left: 5px;
    margin: 0px;
    color: white;
}

#login-submit {
    color: var(--light-green-1);
    background-color: transparent;
    
    outline: none;
    border: 1px solid var(--light-green-1);
    padding: 1.5% 3%;
    border-radius: .25rem;
    
    transition: color .15s ease-in-out,
                background-color .15s ease-in-out,
                font-weight .15s ease-in-out;
}

#login-submit:hover {
    color: var(--topbar-dark);
    background-color: var(--light-green-1);
    font-weight: bold;
}

#login-submit-error-row {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    align-items: center;

    padding-top: 2%;
}

#login-submit-error {
    margin: 0;
}