#login-page {
    width: 100%;
    height: 100%;
}

#top-login {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: flex-end;
    
    position: fixed;
    width: 100%;
    height: auto;

    z-index: 100;
}

#top-logo-login {
    height: auto;
    width: 100%;
}

#login-logomap-area {
    outline: none;
}

#login-content {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;

    background-image: url(../../images/veolia-login.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    min-height: 100vh;
}

#login-card {
    width: 40%;
}

#login-card-body {
    padding: 5% 5% 3% 5%;
    background-color: rgb(33, 37, 41, 0.85);
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem;
}

#login-card-header {
    color: white;
    margin-bottom: 4%;
}

#login-card-footer {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;

    padding: 2%;
    color: var(--topbar-dark);
    background-color: rgba(255, 255, 255, 0.85);
    border-bottom-left-radius: .25rem;
    border-bottom-right-radius: .25rem;
    
    font-weight: bold;
}

/** 2BWebConnect Link **/
#bconnect-link {
    color: var(--topbar-dark);
    font-weight: bold;
    outline: none;
    
    margin-left: 1px;
    background: none;
    border: 0;
    box-sizing: border-box;
    box-shadow: 0 0 0 1px transparent;
    position: relative;

    transition: color 0.25s;
}

#bconnect-link::before, #bconnect-link::after {
    box-sizing: inherit;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;

    border: 2px solid transparent;
    width: 0;
    height: 0;
}

#bconnect-link::before {
    top: 0;
    left: 0;
}

#bconnect-link::after {
    bottom: 0;
    right: 0;
}

#bconnect-link:hover {
}

#bconnect-link:hover::before, #bconnect-link:hover::after {
    width: 100%;
    height: 100%;
}

#bconnect-link:hover::before {
    border-top-color: var(--light-green-1); 
    border-right-color: var(--light-green-1);
    transition: width 0.25s ease-out, 
                height 0.25s ease-out 0.25s; 
}

#bconnect-link:hover::after {
    border-bottom-color: var(--light-green-1);
    border-left-color: var(--light-green-1);
    transition: border-color 0s ease-out 0.5s,
                width 0.25s ease-out 0.5s,
                height 0.25s ease-out 0.75s;
}

@media screen and (max-width: 1000px) {
    #top-logo-login {
        width: 125%;
    }

    #login-card {
        width: 50%;
    }
}

@media screen and (max-width: 800px) {
    #login-card {
        width: 60%;
    }
}

@media screen and (max-width: 600px) {
    #login-content {
        background-image: linear-gradient(var(--sidebar-gradient-1), var(--sidebar-gradient-2), var(--sidebar-gradient-1));
    }
    #top-logo-login {
        width: 150%;
    }

    #login-card {
        width: 70%;
    }
}

@media screen and (max-width: 360px) {
    #login-card-footer {
        font-size: 12px;
    }
}