/* Dropdown Button */
.dropbtn {
    background: transparent;
    color: white;
    border: none;
    
    font-size: 18px;
}

.dropbtn:hover {
    color: var(--light-green-1);
}

.dropdown .dropbtn {
    outline: none;
}

#profile-pic {
    padding-left: 5px;
    margin-left: 0.25em;
    width: 3.6rem;
    border-radius: 30%;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
    display: none;
    position: absolute;
    background-color: #293039;
    width: 160px;
    right: 1px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
}

/* Links inside the dropdown */
.dropdown-content #dropdown-link {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;

    width: 100%;
    color: white;
    padding: 12px 8px;
    text-decoration: none;
}

#dropdown-icon {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;

    color: var(--light-green-1);
    width: 20px;
    height: 20px;
    margin-right: 5px;
}

/* Change color of dropdown links on hover */
.dropdown-content #dropdown-link:hover {
    background-color: #212529;
    color: var(--light-green-1);
    text-decoration: none;
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: flex-start;
}

@media screen and (max-width: 1200px) {
    .dropbtn {
        font-size: 16px;
    }

    #profile-pic {
        min-width: 45px;
    }
}

@media screen and (max-width: 800px) {

    #dropdown
    .dropbtn {
        font-size: 14px;
    }

    #profile-pic {
        min-width: 40px;
    }
}

@media screen and (max-width: 600px) {
    #profile-pic {
        min-width: 35px;
    }
}

@media screen and (max-width: 400px) {
    .dropbtn {
        font-size: 12px;
    }

    #profile-pic {
        min-width: 30px;
    }
}