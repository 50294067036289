#containers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
}

.container-form {
    width: 100%;
    margin-bottom: 20px;
    margin-left: 1em;
}

.container-add-button {
    color: white;
    background-color: var(--topbar-dark);
    font-weight: bold;
    border: none;
    border-radius: .25rem;
    padding: 1% 1%;
    margin-bottom: 3%;
    outline: none;
    transition: color .15s ease-in-out,
                background-color .15s ease-in-out;
    width: 100%;
}

.container-add-button i {
    margin-right: 8px;
}

.container-add-button:hover {
    color: var(--light-green-1);
    background-color: var(--darker);
}

.containers-table {
    width: 100%;
    margin-bottom: 0px;
    color: #212529;
    border: 1px solid rgba(0,0,0,.125);
}

.containers-table-head {
    width: 100%;
    background-color: var(--topbar-dark);
    color: white;
    text-align: center;
}

.containers-table-body {
    width: 100%;
    text-align: center;
    background-color: white;
    cursor: pointer;
}

.containers-table-row {
    width: 100%;
}

.containers-table-row:hover {
    background-color: var(--light-green-1);
}

.containers-list-buttons {
    justify-content: space-around;
    align-items: flex-start;
}

.container-form-control {
    display: flex;
    justify-content:flex-start;
    align-items: flex-end;
}

.container-form-control button:last-of-type {
    margin-left: 10px;
}

.container-form .col-sm-12 {
    margin-bottom: 20px;
}

.container-form-errors {
    color: crimson;
    position: absolute;
}

.edit-button {
    align-items: initial;
}

.container-buttons-edit {
    margin: auto;
}

.registered-containers-table {
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--light-green-1);
    background-color: var(--topbar-dark);
    font-weight: bold;
    border: none;
    border-radius: .25rem;
    padding: 1% 1%;
    margin-bottom: 0;
    outline: none;
    transition: color .15s ease-in-out,
                background-color .15s ease-in-out;
    width: 100%;
}

.registered-containers-table i {
    margin-right: 8px;
}

.container__table {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 100%;
}

@media screen and (max-width: 850px) {

    #containers {
        width: 98%;
    }

    .container-add-button {
        width: 100%;
        font-size: small;
        margin-bottom: 1rem;
    }

    .container-form {
        width: 100%;
        font-size: small;
        margin: 0%;
    }

    ::placeholder {
        font-size: small;
    }

    .container-form select{
        font-size: small;
    }

    .container-form .btn {
        font-size: smaller;
    }

    .registered-containers-table {
        width: 100%;
    }

    .containers-list-buttons .btn {
        font-size: 1.5em;
        padding: 0 0.8rem 0 0.8rem;
    }

    .container__table {
        width: 100%;
        margin-bottom: 0px;
        color: #212529;
        border: 1px solid rgba(0,0,0,.125);
        overflow: visible;
        font-size: small;
    }

    .container__table table {
      border: 0;
    
    }

    .container__table table caption {
        font-size: 1.3em;
      }
      
      .container__table table thead {
        width: 100%;
        border: none;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
      }
      
      .container__table table tr {
        border-bottom: 0.5em solid var(--topbar-dark);
        display: flex;
        flex-direction: column;
      }
      
      .container__table table td {
        border-bottom: 1px solid #ddd;
        font-size: .8em;
        text-align: right;
      }
      
      .container__table table td::before {
        /*
        * aria-label has no advantage, it won't be read inside a table
        content: attr(aria-label);
        */
        content: attr(data-label);
        float: left;
        font-weight: bold;
        text-transform: uppercase;
      }
      
      .container__table table td:last-child {
        border-bottom: 0;
      }
}