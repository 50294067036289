#top-nav {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: flex-end;
    position: fixed;
    width: 100%;
    height: auto;

    z-index: 100;
}

#nav-logo {
    height: auto;
    width: 100%;
}

#nav-logomap-area {
    outline: none;
}

#nav-dropdown {
    position: absolute;
    right: 0px;
    padding-bottom: 10px;
    padding-right: 20px;
}

@media screen and (max-width: 1200px) {
    #nav-dropdown {
        padding-bottom: 8px;
        padding-right: 16px;
    }
}

@media screen and (max-width: 1000px) {
    #nav-logo {
        width: 125%;
    }

    #nav-dropdown {
        padding-bottom: 6px;
        padding-right: 12px;
    }
}

@media screen and (max-width: 800px) {
    #top-nav {
        height: 7.9vh;
    }


    #nav-dropdown {
        padding-bottom: 0px;
        padding-right: 10px;
    }
}

@media screen and (max-width: 600px) {
    #nav-logo {
        width: 150%;
    }

    #nav-dropdown {
        padding-bottom: 2px;
        padding-right: 4px;
    }
}

@media screen and (max-width: 400px) {
    #nav-dropdown {
        padding-bottom: 0px;
        padding-right: 0px;
    }
}

@media (max-width: 850px) and (orientation: landscape) {

}