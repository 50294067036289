.premios {
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
}

button.btn.active-tab, 
button.active-tab:focus {
    color: var(--light-green-1)!important;
    background-color: var(--darker)!important;
}

.tabs-style {
    font-weight: bolder;
    border-radius: .25rem;
    margin-top: 0%;
}

.nav.tabs-style {
    margin-bottom: 1.5em;
}

.style-buttons {
    margin-right: 10px;
}

.style-buttons i {
    margin-right: 8px;
}

.prize-form {
    width: 55%;
}

.edit-form {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.edit-form #modalBody {
    flex-direction: column;
}

.prize-form-errors {
    color:crimson;
}

.add-button {
    display: flex !important;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    width: 30%;
}

.card {
    margin-right: 6px;
    margin-bottom: 6px;
    width: 15.4rem;
    flex-shrink: 0;
}

.card-container {
    display: grid;
    gap: 2.6px;
    grid-template-columns: repeat(5, 1fr);
    grid-auto-rows: auto;
    justify-content: center;
    align-content: center;
    overflow-x: auto;
    width: auto;
    max-width: 100%;
}

.card-body {
    display: flex;
    flex-direction: column;
    padding: 0.95rem;
}

.card-body p {
    padding-bottom: 8px;
    font-size: small;
    color: #666;
}

.card-body__info {
    flex-grow: 1;
}

.card-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.buttons-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.btn-group .buttons-card:not(:last-child) {
    margin-right: 8px;
}

.buttons-activate-prize {
    width: 100%;
    margin-bottom: 8px;
}

.prize-image {
    object-fit: cover;
    width: 100%;
    height: 150px;
}

.prize-alerts-table {
    width: 100%;
    margin-bottom: 0px;
    color: #212529;
    border: 1px solid rgba(0,0,0,.125);
}

.prize-alerts-table-head {
    width: 100%;
    background-color: var(--topbar-dark);
    color: white;
    text-align: center;
}

.prize-alerts-table-body {
    width: 100%;
    text-align: center;
    background-color: white;
    cursor: pointer;
}

.prize-alerts-table-row:hover {
    background-color: var(--light-green-1);
}

.prizeAlerts {
    width: 100%;
    margin-top: 0;
}

.prize-alerts-button:hover {
    color: var(--light-green-1);
    background-color: var(--darker);
}

.prize-alerts-button {
    color: white;
    background-color: var(--topbar-dark);
    font-weight: bold;
    border: none;
    border-radius: .25rem;
    padding: 0.3rem 0 0.3rem 0 !important;
    margin-bottom: 1%;
    margin-top: 0%;
    outline: none;
    transition: color .15s ease-in-out,
                background-color .15s ease-in-out;
    width: 100%;
}

.prize-alerts-button i {
    margin-right: 8px;
}

.send-button {
    margin: auto;
    align-items: center;
}

.btn.send-button {
    padding: 0%;
}

.prizes__statistics {
    width: 100%;
}

.dashboard {
     display: flex;
     margin-bottom: 25px;
}

.dashboard__title {
    font-size: 14px;
    margin-left: 1em;
    margin-bottom: 0%;
}

.dashboard .dashboard__controller {
    padding: 1em;
    text-align: center;
}

.dashboard .dashboard__date-input {
    display: flex;
    height: 2em;
}

.dashboard .dashboard__date-input > input{
   margin-left: 1em;
   width: 4em;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.dashboard__button--date-input {
    width: 100%;
    margin-top: 1em;
}

@media (max-width: 850px) {

    /* General configuration - tabs/buttons style and space for content in general */
    .premios {
        width: 100%;
        padding: 0;
    }

    .nav.tabs-style {
        width: 100%;
        margin: 0.4em;
        font-size: xx-small;
    }

    .style-buttons {
        font-size: xx-small;
        padding-bottom: 0.6em;
        padding-top: 0.6em;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-content: center;
    }

    .style-buttons .btn {
        font-size: 1.5em;
        padding: 0.3rem 0.3rem 0.3rem 0.3rem;
        width: 100%;
    }

    /* Prizes list (see prizes tab) style for mobile */
    .card-container {
        display: grid;
        gap: 2px;
        grid-template-columns: repeat(1, 1fr);
        font-size: smaller;
        margin-left: 1em;
        grid-auto-rows: auto;
        justify-content: center;
        align-content: center;
        overflow-x: auto;
        width: auto;
        max-width: 100%;
    }

    .card-container .btn {
        font-size: small;
    }

    /* Add new prize style for mobile */
    .prize-form {
        width: 98%;
        font-size: small;
        margin-top: 1em;
        margin-bottom: 1rem;
    }

    .prize-form input {
        font-size: small;
    }

    .add-button.btn {
        font-size: smaller;
        font-weight: 700;
    }

    /* Statistics session style for mobile */
    .prizes__statistics {
        width: 100%;
        padding: 1em 0.5em 0 0.5em;
        font-size: small;
    }
    .prize-alerts-button{
        margin-bottom: 0;
        padding: 1% 1%;
    }

    /* no title/legend for graphic */
    .dashboard__title {
        display: none;
    }

    /* no graphic in mobile mode */
    .dashboard {
        display: none;
    }

    /* table configuration */
    .prizeAlerts table {
        border: 0;
    }
  
    .prizeAlerts table caption {
          font-size: 1.3em;
    }
        
    .prizeAlerts table thead {
        width: 100%;
        border: none;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
    }
        
    .prizeAlerts table tr {
        border-bottom: 0.5em solid var(--topbar-dark);
        display: flex;
        flex-direction: column;
    }
        
    .prizeAlerts table td {
        border-bottom: 1px solid #ddd;
        font-size: .8em;
        text-align: right;
    }
        
    .prizeAlerts table td::before {
        content: attr(data-label);
        float: left;
        font-weight: bold;
        text-transform: uppercase;
    }
        
    .prizeAlerts table td:last-child {
        border-bottom: 0;
    }

    /* configuration landscape - show card prizes */
    @media (max-width: 850px) and (orientation: landscape) {
       
        .card {
            width: 13rem;
        }

        .card-container {
            display: grid;
            gap: 2px;
            grid-template-columns: repeat(3, 1fr);
            font-size: smaller;
            margin-left: 1em;
            grid-auto-rows: auto;
            justify-content: center;
            align-content: center;
            overflow-x: auto;
            width: auto;
            max-width: 100%;
        }
    }
}