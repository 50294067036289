#content {
    display: flex;
    padding: 15px;
    height: 100%;
}

.table__spinner {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.table__spinner .spinner-border {
    color: var(--light-green-1);
    margin-right:  1em;
}

@media (max-width: 850px) {

    #profile-pic {
        width: 0.8em;
        margin-bottom: 0.2em;
    }

    #content-row {
        height: 100%;
        overflow: hidden;
    }

    #content {
        overflow: auto;
        padding-top: 1.2rem;
        margin-bottom: 1rem;
    }

    .dropbtn:first-child {
        font-size: small;
    }
}