.pagination-style {
    display: flex;
    justify-content: center;
}

.pagination-style .pagination {
    margin: 0;
}

.pagination-style .page-link {
    color: rgb(41, 48, 57) !important;
}

.page-item.active .page-link {
    background-color: var(--light-green-1) !important;
    border-color: var(--light-green-1) !important;
}

.page-link:focus {
    box-shadow: 0 0 0 .2rem rgba(133, 206, 54, .25) !important;
}