.content-footer {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    bottom: 0px;
    padding: 1px;
    width: 100%;
    background-color: white;
    border-top: 1px solid #777777;
    z-index: 1;
}

.footer-text {
    padding-right: 2%;
    color: black;
}