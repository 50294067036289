.alertas {
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    width: 100%;
}

.open-tabs-button {
    display: none;
}

@media (max-width: 850px) {

    .responsiveAlertsMobile {
        width: 100%;
        margin: 0%;
    }

    .alerts-title-resp {
        width: 100%;
        font-size: small;
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--light-green-1);
        background-color: var(--topbar-dark);
        font-weight: bold;
        border: none;
        border-radius: .25rem;
        padding: 1% 1%;
        margin-bottom: 0;
        margin-top: 1rem;
        outline: none;
        transition: color .15s ease-in-out, background-color .15s ease-in-out;
    }

    .open-tabs-button {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        color: white;
        background-color: var(--topbar-dark);
        font-weight: bold;
        border: none;
        border-radius: .2rem;
        padding: 1% 1%;
        outline: none;
         transition: color .15s ease-in-out, background-color .15s ease-in-out;
        width: 100%;
        font-size: small;
    }

    .open-tabs-button i {
        margin-left: 8px;
    }

    .alertas {
        width: 98%;
        flex-direction: column;
        align-items: center;
    }
}