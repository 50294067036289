/* general CSS account session */
#account {
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: center;
    margin-top: 0;
    width: inherit;
    height: 100%;
    font-size: 14px;
    background-color: var(--background-grey);
}

#account #content-row {
    width: 100%;
    flex-grow: 1;
    background-color: var(--background-grey);
}

#account #sidebar {
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: center;
    
    position: fixed;
    width: inherit;
    height: 80%;
    padding-bottom: 10px;
    overflow-x: hidden;
}

#account .sidebar-full#sidebar {
    min-width: 210px;
    cursor: pointer;
}

#account .sidebar-hidden#sidebar {
    min-width: 60px;
}

#account .sidebar-col-full#sidebar-col {
    min-width: 230px;
}

#account #content-col {
    display: flex;
    flex-direction: column;
    overflow: scroll;
    bottom: 0px;
    padding: 1px;
    width: 100%;
    background-color: white;
    border-top: 1px solid #777777;
    z-index: 1;
    width: 100%;
}

#account h1 {
    margin-top: 30px;
}

/* profile session */
.account-profile-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.content-container {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.content-container div.content {
    display: flex;
    justify-content: center;
    width: 100%;
    flex-grow: 1;
    overflow: auto;
}

.titles-style {
    font-size: 2em;
    margin-top: 1em !important;
    margin-bottom: 0% !important;
}

#profile-pic-account {
    width:23vw;
    height: auto;
    border-radius: .50rem;
    border: 3px solid var(--topbar-dark);
    margin-bottom: 10px;
}

.col-form-label {
    margin-right: 10px;
}

.account-email-box {
    width: 25%;
}

.profile__actions {
    display: flex;
}

.profile__actions > button {
    margin-right: 1em;
}

.form-changename-errors {
    color: rgb(230, 0, 0);
}

.account-foto-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.change-image-button {
    display: flex;
    justify-content: end;
    flex-direction: flex-end;
}

#image-to-change {
    width:300px;
    height: auto;
    border-radius: .50rem;
    border: 3px solid var(--topbar-dark);
    margin-bottom: 10px;
}

/* create new account session */
.newAcc {
    width: 96%;
    margin-top: 2%;
}

.newAcc__form {
    margin-left: 3px;
    width: 99%;
}

.newAcc__form .form-group {
    margin-bottom: 1em;
}

.newAcc__form .form-check-input {
    position: relative;
    margin-left: 0.5rem;
}

.newacc-add-button {
    color: white;
    background-color: var(--topbar-dark);
    font-weight: bold;
    border: none;
    border-radius: .25rem;
    padding: 1% 1%;
    margin-bottom: 1.5em;
    outline: none;
    transition: color .15s ease-in-out,
                background-color .15s ease-in-out;
    width: 100%;
}

.newacc-add-button i {
    margin-right: 8px;
}

.newacc-add-button:hover {
    color: var(--light-green-1);
    background-color: var(--darker);
}

.registered-managers-table {
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--light-green-1);
    background-color: var(--topbar-dark);
    font-weight: bold;
    border: none;
    border-radius: .25rem;
    padding: 1% 1%;
    margin-bottom: 0;
    outline: none;
    transition: color .15s ease-in-out,
                background-color .15s ease-in-out;
    width: 100%;
}

.registered-managers-table i {
    margin-right: 8px;
}

.create-newacc-button {
    display: flex;
    justify-content: end;
    margin-bottom: 1em;
}

.newacc-form-errors {
    color: crimson;
}

.managers-table {
    width: 100%;
    margin-bottom: 2em;
    color: #212529;
    border: 1px solid rgba(0,0,0,.125);
}

.managers__table {
    margin-bottom: 1rem;
}

.managers-table-head {
    width: 100%;
    background-color: var(--topbar-dark);
    color: white;
    text-align: center;
}

.managers-table-body {
    width: 100%;
    text-align: center;
    background-color: white;
    cursor: pointer;
}

.managers-table-row {
    width: 100%;
}

.managers-table-row:hover {
    background-color: var(--light-green-1);
}

/* mobile version CSS */
@media (max-width: 850px) {

    .titles-style {
        font-size: 12.8px;
    }

    .account-email-box {
        width: 60vw;
        font-size: small;
    }

    .account-email-box input {
        font-size: small;
    }
    
    .newacc-add-button{
        width: 100%;
        font-size: small;
    }

    .newAcc__form {
        width: 98%;
        font-size: small;
    }

    .newAcc__form .btn {
        font-size: small;
    }

    .newAcc__form .form-check-label {
        margin-left: 0;
    }

    .newAcc {
        margin: 0.8rem;
    }

    .registered-managers-table {
        margin-top: 0;
        font-size: small;
    }

    .profile__actions {
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 98%;
        font-size: small;
        padding: 0 0 0 0.8em;
    }

    .profile__actions .btn {
        font-size: smaller;
        height: 3.8em;
    }

    .profile__actions .btn:last-child {
        width: 100%;
    }

    .newAcc table {
        border: 0;
        width: 100%;
        padding-bottom: 1em;
    }
  
   .newAcc table caption {
        font-size: 1.3em;
    }
        
    .newAcc table thead {
        border: none;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
    }
        
    .newAcc table tr {
        border-bottom: 0.5em solid var(--topbar-dark);
        display: flex;
        flex-direction: column;
    }
        
    .newAcc table td {
        border-bottom: 1px solid #ddd;
        font-size: .8em;
        text-align: right;
    }
        
    .newAcc table td::before {
          /*
          * aria-label has no advantage, it won't be read inside a table
          content: attr(aria-label);
          */
        content: attr(data-label);
        float: left;
        font-weight: bold;
        text-transform: uppercase;
    }
        
    .newAcc table td:last-child {
        border-bottom: 0;
        padding-bottom: 1em;
    }

    .newAcc .footer-text {
        font-size: smaller;
    }
    
    .content-container div.content { 
        margin-bottom: 1rem;
    }

    .form-check-input {
        margin-left: 0.6rem !important;
    }

    .form-check-label {
        margin-left: 2rem;
    }

    @media (max-width: 850px) and (orientation: landscape) {

        #account .content-container div.content {
            margin-top: var(--top-margin-850px);
        }

        .account-email-box {
            width: 40%;
        }

        .profile__actions {
            width: 50%;
        }
    }
}