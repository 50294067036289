.table-suggestions-head {
    text-align: center;
    font-size: 13.5px;
    background-color: var(--topbar-dark);
    color: white;
}

.suggestions table {
  table-layout: fixed;
}

.table-suggestions-body {
    text-align: center;
    align-items: stretch;
    background-color: white;
}

.table-suggestions-body tr:hover {
    background-color: var(--light-green-1);
}

@media screen and (max-width: 850px) {

    .empty-row-sugg {
        display: none;
    }

}