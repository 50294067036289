 .messages {
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
} 

.messages table {
    table-layout: fixed;
}

.message-writenewmsg-button {
    color: white;
    background-color: var(--topbar-dark);
    font-weight: bold;
    border: none;
    border-radius: .25rem;
    padding: 1% 1%;
    margin-bottom: 3%;
    outline: none;
    transition: color .15s ease-in-out,
                background-color .15s ease-in-out;
    width: 100%;
}

.sent-messages-label {
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--light-green-1);
    background-color: var(--topbar-dark);
    font-weight: bold;
    border: none;
    border-radius: .25rem;
    padding: 1% 1%;
    margin-bottom: 0;
    outline: none;
    transition: color .15s ease-in-out,
                background-color .15s ease-in-out;
    width: 100%;
}

.message-writenewmsg-button:hover {
    color: var(--light-green-1);
    background-color: var(--darker);
}

.form-message {
    width: 100%;
    margin-bottom: 3%;
}

.message-form-errors {
    color: crimson;
}

.messages-table-head {
    width: 100%;
    background-color: var(--topbar-dark);
    color: white;
    padding: 0.5% 0.5%;
    text-align: center;
    border-radius: .25rem;
}

.sent-messages-table-body {
    width: 100%;
    text-align: center;
    padding: 0.5% 0.5%;
    background-color: white;
    cursor: pointer;
}

.messages-table-row:hover {
    background-color: var(--light-green-1);
}

.messages-table-row {
    width: 100%;
    text-align: center;
    background-color: white;
}

.messages .colspan-style {
    width: 100%;
    text-align: center;
    background-color: white;
    word-wrap: break-word;
}

.message-form {
    width: 100%;
}

.send-message-button {
    color: var(--darker);
    background-color: var(--light-green-1);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    padding: 0.7% 0.7%;
    border-radius: .25rem;
    width: 25%;
}

.box-send-message {
    display: flex;
    resize: vertical;
    border-radius: .25rem;
    border: 0px solid;
    height: 114px !important;
    width: 100%;
}

.hideContent {
    max-width: 30px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap
}

.message-writenewmsg-button i, 
.sent-messages-label i {
    margin-right: 8px;
}

#modalBody {
    display: flex;
    justify-content: center;
}

@media (max-width: 850px) {

    .message-form {
        width: 98%;
        font-size: small;
    }

    .message-form select {
        font-size: small;
    }
    
    .message-form input {
        font-size: small;
    }

    .send-message-button {
        width: 100%;
        padding: 0.3em 0 0.3em 0;
    }

    .messages .colspan-style {
        color: var(--light-green-1);
        font-weight: 700;
        text-align: end;
    }

    .empty-row-msg {
        display: none;
    }

    .hideContent {
        display: none;
    }

    .message-writenewmsg-button {
        font-size: small;
        margin-bottom: 1rem;
    }

    .sent-messages-label {
        font-size: small;
    }

    .messages {
        width: 100%;
    }
    
    .messages .btn {
        font-size: 1.4em;
        padding: 0 0.7rem 0 0.7rem;
    } 

    .messages table {
        border: 0;
        width: 100%;
        margin-bottom: 2em;
    }

    .messages table caption {
        font-size: 1.3em;
    }
      
    .messages table thead {
        width: 100%;
        border: none;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
      }
      
    .messages table tr {
        border-bottom: 0.5em solid var(--topbar-dark);
        display: flex;
        flex-direction: column;
      }
      
    .messages table td {
        border-bottom: 1px solid #ddd;
        font-size: .8em;
        text-align: right;
      }
      
    .messages table td::before {
        /*
        * aria-label has no advantage, it won't be read inside a table
        content: attr(aria-label);
        */
        content: attr(data-label);
        float: left;
        font-weight: bold;
        text-transform: uppercase;
      }
      
    .messages table td:last-child {
        border-bottom: 0;
      }

}