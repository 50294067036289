.add-new-operator {
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
    width: 100%;
}

.new-operator-button {
    color: white;
    background-color: var(--topbar-dark);
    font-weight: bold;
    border: none;
    border-radius: .25rem;
    padding: 0.8% 1%;
    margin-bottom: 1.5%;
    outline: none;
    transition: color .15s ease-in-out,
                background-color .15s ease-in-out;
    width: 100%;
}

.new-operator-button i {
    margin-right: 8px;
}

.new-operator-form {
    align-items: flex-end;
    margin-bottom: 0.5em;
}

.new-operator-form .col-sm-12 {
    margin-bottom: 3em;
}

.operator-form-errors {
    color: crimson;
    font-size: small;
    position: absolute;
}