.graphic__controller {
    margin-left: 1rem;
    margin-top: 2rem;
    width: 100%;
}

.graphic__controller select, 
.graphic__controller input {
    width: 55%;
}

.graphic__controller > div {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
    align-items: center;
}

.graphic__date-input input {
    margin-left: 0.8rem;
    border-radius: 0.3rem;
}

.graphic__user-input select {
    border-radius: 0.3rem;
}

.graphic__search-initialDate,
.graphic__search-finalDate {
    width: 100%;
    margin: auto;
    text-align: center;
}

.finalDate {
    margin-top: 0.3rem;
    margin-left: 1.16rem;
}

@media screen and (max-width: 850px) {

    .graphic__controller {
        margin: 0.5rem;
        width: 100%;
        font-size: small;
    }

    .graphic__search-button.btn {
        font-size: x-small;
    }

    .graphic__controller select, 
    .graphic__controller input {
        width: 60%;
    }
} 

    @media (max-width:850px) and (orientation: landscape) {

        .graphic__controller {
            display: flex;
            flex-direction: row !important;
            align-items: center;
            justify-content: center;
            margin-top: 1rem;
        }

        .graphic__controller select, 
        .graphic__controller input {
            width: 90%;
        }

        .graphic__controller > div:last-child { 
            margin-top: 0.5rem;
            margin-bottom: 0;
        }

        .graphic__search-initialDate,
        .graphic__search-finalDate {
            display: flex;
            flex-direction: row;
        }

        .graphic__search-initialDate {
            margin-bottom: 0.8rem;
        }

        .graphic__search-initialDate label {
            margin: 0;
        }
        
        .finalDate {
            margin-top: 0;
            margin-bottom: 0;
        }

        .graphic__date-input {
            margin-right: 2rem;
        }

        .graphic__user-input {
            margin-right: 1.2rem;
        }
    }


