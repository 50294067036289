.map__container {
    width: 100%;
    z-index: 0;
    overflow: hidden;
    max-height: 73vh;
    height: 100%;
}

@media (max-width: 850px) {
    
    .map__container{
        max-height: 85vh;
    }
}