.collections-section {
    width: 100%;
}

.collections-table-head {
    text-align: center;
    font-size: 13.5px;
    background-color: var(--topbar-dark);
    color: white;
}

.collections-table-body {
    width: 100%;
    text-align: center;
    align-items: stretch;
    background-color: white;
    overflow-x: auto;
}

.collections-table-body tr:hover {
    background-color: var(--light-green-1);
}

.table-title {
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--light-green-1);
    background-color: var(--topbar-dark);
    font-weight: bold;
    border: none;
    border-radius: .25rem;
    padding: 0.8% 1%;
    margin-bottom: 0;
    outline: none;
    transition: color .15s ease-in-out,
                background-color .15s ease-in-out;
    width: 100%;
}

.table-title i {
    margin-right: 8px;
}

@media screen and (max-width: 850px) {

    .collections-section table {
        border: 0;
    }
    
    .collections-section table caption {
        font-size: 1.3em;
    }
    
    .collections-section table thead {
        width: 90%;
        border: none;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
    }
    
    .collections-section table tr {
        border-bottom: 0.5em solid var(--topbar-dark);
        display: flex;
        flex-direction: column;
    }
    
    .collections-section table td {
        border-bottom: 1px solid #ddd;
        font-size: .8em;
        text-align: right;
    }
    
    .collections-section table td::before {
        /*
        * aria-label has no advantage, it won't be read inside a table
        content: attr(aria-label);
        */
        content: attr(data-label);
        float: left;
        font-weight: bold;
        text-transform: uppercase;
    }
    
    .collections-section table td:last-child {
        border-bottom: 0;
    }
    
    .table-title  {
        margin-top: 0%;
        font-size: small;
    }
}
    
    