.operators-list {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.operators-table-title {
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--light-green-1);
    background-color: var(--topbar-dark);
    font-weight: bold;
    border: none;
    border-radius: .25rem;
    padding: 0.8% 1%;
    margin-bottom: 0;
    outline: none;
    transition: color .15s ease-in-out,
                background-color .15s ease-in-out;
    width: 100%;
}

.operators-table-head {
    text-align: center;
    font-size: 13.5px;
    background-color: var(--topbar-dark);
    color: white;
}

.operators-table-body {
    width: 100%;
    text-align: center;
    align-items: stretch;
    background-color: white;
    overflow-x: auto;
}

.operators-table-body tr:hover {
    background-color: var(--light-green-1);
}