.hamburger {
    padding: 5px;
    cursor: pointer;
    transition-property: opacity, filter;
    transition-duration: 0.15s;
    transition-timing-function: linear;
    font: inherit;
    color: inherit;
    text-transform: none;
    background-color: transparent;
    border: 0;
    margin: 0;
    overflow: visible;
    outline: none !important;
}

    .hamburger:hover {
      opacity: 0.5;
    }

    .hamburger.is-active:hover {
      opacity: 0.7; 
    }

    .hamburger.is-active .hamburger-inner,
    .hamburger.is-active .hamburger-inner::before,
    .hamburger.is-active .hamburger-inner::after {
      /*background-color: #85ce36;*/
      background-color: var(--topbar-dark);
    }

  
.hamburger-box {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  
  width: 40px;
  height: 24px;
  position: relative;
}


.hamburger-inner {
    display: block;
}

    .hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
      width: 40px;
      height: 4px;
      /*background-color: #85ce36;*/
      background-color: var(--topbar-dark);
      border-radius: 4px;
      position: absolute;
      transition-property: transform;
      transition-duration: 1s;
      transition-timing-function: ease;
    }

    .hamburger-inner::before, .hamburger-inner::after {
      content: "";
      display: block;
    }

    .hamburger-inner::before {
      top: -10px;
    }

    .hamburger-inner::after {
      bottom: -10px;
    }


/*
   * Arrow
   */

   .hamburger--arrow.is-active .hamburger-inner {
     transform: scale(0.65, 1); }
   
   .hamburger--arrow.is-active .hamburger-inner::before {
     transform: translate3d(-9.75px, 5px, 0) rotate(-30deg) scale(0.5, 1); }
   
   .hamburger--arrow.is-active .hamburger-inner::after {
     transform: translate3d(-9.75px, -5px, 0) rotate(30deg) scale(0.5, 1); }

/*.hamburger--arrow.is-active .hamburger-inner {
  transform: scale(0.8, 1); }

.hamburger--arrow.is-active .hamburger-inner::before {
  transform: translate3d(-10px, 3px, 0) rotate(-35deg) scale(0.7, 1); }

.hamburger--arrow.is-active .hamburger-inner::after {
  transform: translate3d(-10px, -3px, 0) rotate(35deg) scale(0.7, 1); }*/

