.suggestions {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  width: 100%;
}

.sug-tabela {
  width: 80%;
}

.sug-filter {
  width: 20%;
}

.suggestions .colspan-style {
  text-align: center;
  word-wrap: break-word;
}

.suggestionsTable-title {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--light-green-1);
  background-color: var(--topbar-dark);
  font-weight: bold;
  border: none;
  border-radius: .25rem;
  padding: 1% 1%;
  margin-bottom: 0;
  outline: none;
  transition: color .15s ease-in-out,
              background-color .15s ease-in-out;
}

@media screen and (max-width: 850px) {

  .suggestions {
    width: 100%;
    flex-direction: column;
    align-items: center;
  }

  .responsiveSuggestionsMobile {
    width: 100%;
    margin: 0%;
  }

  .suggestions-title-resp {
    width: 100%;
    font-size: small;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--light-green-1);
    background-color: var(--topbar-dark);
    font-weight: bold;
    border: none;
    border-radius: .25rem;
    padding: 1% 1%;
    margin-bottom: 0;
    margin-top: 1rem;
    outline: none;
    transition: color .15s ease-in-out, background-color .15s ease-in-out;
  }

   .suggestions .colspan-style {
        color: var(--light-green-1);
        font-weight: 700;
        text-align: end;
    }

    .suggestionsTable-title {
      font-size: small;
    }

    .suggestions table {
        border: 0;
    }

    .suggestions table caption {
        font-size: 1.3em;
      }
      
      .suggestions table thead {
        width: 90%;
        border: none;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
      }
      
      .suggestions table tr {
        border-bottom: 0.5em solid var(--topbar-dark);
        display: flex;
        flex-direction: column;
      }
      
      .suggestions table td {
        border-bottom: 1px solid #ddd;
        font-size: .8em;
        text-align: right;
      }
      
      .suggestions table td::before {
        content: attr(data-label);
        float: left;
        font-weight: bold;
        text-transform: uppercase;
      }
      
      .suggestions table td:last-child {
        border-bottom: 0;
      }
}