.history {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.history__title {
    display: flex;
    justify-content: center;
    font-weight: 600;
    font-size: x-large;
}

.history__content {
    display: flex;
    width: 100%;
}

.history__content .graphic {
    flex: 300%;
}

.graphic__legend {
    margin-bottom: 0;
    margin-left: 2rem;
}

.total__weight {
    margin-top: 0.8rem;
    margin-left: 1.8rem;
}

@media screen and (max-width: 850px) {

    .history {
        width: 98%;
        flex-direction: column;
        align-items: center;
    }

    .history__menu {
        width: 100%;
        margin-bottom: 1rem;
    }

    .graphic {
        width: 100%;
    }
    
    .graphic__legend {
        font-size: x-small;
        margin-left: 0.8rem;
    }

    .history__title {
        font-size: small;
        align-items: center;
    }

    .total__weight {
        font-size: small;
        margin-left: 0.2rem;
    }

    @media (max-width: 850px) and (orientation: landscape) {

        .history__title {
            margin-bottom: 1rem;
        }

        .history__menu {
            margin: 0;
        }

        .history__menu .open-tabs-button {
            margin-bottom: 0.5rem;
        }
    }
}