.operators {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    width: 95%;
    margin-top: 2%;
}

/* mobile version CSS */
@media (max-width: 850px) {
    
    .operators {
        margin: 0.8rem;
    }

    .new-operator-form {
        margin-top: 0.5rem;
        font-size: small;
    }

    .new-operator-form .col-sm-12 {
        margin-bottom: 0.8rem;
    }

    .new-operator-button {
        margin-top: 0.8rem;
        margin-bottom: 1rem;
        font-size: small;
    }

    .operators-table-title {
        font-size: small;
    }

    .operators table {
        border: 0;
        width: 100%;
        padding-top: 0%;
        padding-bottom: 1em;
    }
  
    .operators table caption {
        font-size: 1.3em;
    }
        
    .operators table thead {
        border: none;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
    }
        
    .operators table tr {
        border-bottom: 0.5em solid var(--topbar-dark);
        display: flex;
        flex-direction: column;
    }
        
    .operators table td {
        border-bottom: 1px solid #ddd;
        font-size: .8em;
        text-align: right;
    }
        
    .operators table td::before {
          /*
          * aria-label has no advantage, it won't be read inside a table
          content: attr(aria-label);
          */
        content: attr(data-label);
        float: left;
        font-weight: bold;
        text-transform: uppercase;
    }
        
    .operators table td:last-child {
        border-bottom: 0;
        padding-bottom: 1em;
    }

    .operators .footer-text {
        font-size: smaller;
    }

    @media (max-width: 850px) and (orientation: landscape) {
    
        .operators {
            margin: 0%;
        }

    }
    
}