/* Sidebar */
#sidebar {
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: center;
    
    position: fixed;
    width: inherit;
    height: 90%;
    padding-bottom: 10px;
    overflow-y: auto;
    overflow-x: hidden;
}

.sidebar-full#sidebar {
    min-width: 205px;
}

.sidebar-hidden#sidebar {
    min-width: 60px;
}


/* Dashboard Item */
#dashboard-item {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;

    width: 100%;
    min-height: 60px;

    color: #ffffff;
    padding-top: 10px;
    padding-bottom: 10px;
}

.sidebar-full #dashboard-item {
    justify-content: flex-start;
    padding-left: 15px;
}

.sidebar-hidden #dashboard-item {
    justify-content: center;
}


/* Hamburger Button Box */
#button-box {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
}

.sidebar-full #button-box {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    
    position: absolute;
    padding-right: 5px;
    right: 0px;
}

.sidebar-hidden #button-box {
}


/* Dashboard Icon */
#dashboard-icon {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;

    width: 30px;
    height: 30px;
}

.sidebar-full #dashboard-icon {
    margin-right: 10px;
}

.sidebar-hidden #dashboard-icon {
    display: none;
}

/* Dashboard Text */
#dashboard-text {
}

.sidebar-full #dashboard-text {
}

.sidebar-hidden #dashboard-text {
    display: none;
}


/* Side Links */
#side-link {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;

    min-height: 40px;
    margin-top: 2px;
    margin-bottom: 2px;
    padding-top: 8px;
    padding-bottom: 8px;
    text-decoration: none;
}

#side-link.active-link {
}

#side-link:hover:not(.active-link) {
}

.sidebar-full #side-link {
    justify-content: flex-start;

    width: 90%;
    padding-left: 10px;
    border-radius: .25rem;
    color: #ffffff80;
}

.sidebar-full #side-link.active-link {
    background-color: var(--topbar-dark);
    color: var(--light-green-1);
}

.sidebar-full #side-link:not(.active-link):not(.disabled-link) {
    background-color: rgb(33, 37, 41, 0.5);
}

.sidebar-full #side-link:hover:not(.active-link):not(.disabled-link) {
    color: white;
    background-color: var(--topbar-dark);
    border-radius: .25rem;
}

.sidebar-full.disabled-link {
    background-color: rgb(33, 37, 41, 0.25);
}

.sidebar-hidden #side-link {
    justify-content: center;
    width: 85%;
}

.sidebar-hidden #side-link:not(.disabled-link) {
    color: var(--topbar-dark);
}

.sidebar-hidden #side-link.active-link {
    background-color: var(--topbar-dark);
    color: var(--light-green-1);
    border-radius: .25rem;
}

.sidebar-hidden #side-link:hover:not(.active-link):not(.disabled-link) {
    color: white;
    background-color: var(--topbar-dark);
    border-radius: .25rem;
}

.sidebar-hidden.disabled-link {
    color: rgb(33, 37, 41, 0.5);
}

#inner-link {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.sidebar-full #inner-link {
    flex-flow: row wrap;
}

.sidebar-hidden #inner-link {
    flex-flow: column wrap;
}

/* Side Icons */
#side-icon {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;

    width: 30px;
    height: 30px;
}

.sidebar-full #side-icon {
    margin-right: 10px;
}

.sidebar-hidden #side-icon {
    margin: 0px;
}


/* Side Links Text */
#side-link-text {
}

.sidebar-full #side-link-text {
    margin-right: 10px;
}

.sidebar-hidden #side-link-text {
    display: none;
}

/* Scroll */
#sidebar::-webkit-scrollbar {
    display: none;
}

@media screen and (max-width: 600px) {
    #dashboard-item {
        display: none;
    }

    .sidebar-hidden #side-link-text {
        display: flex;
        font-size: 45%;
    }
}

#account .sidebar-hidden .sidenav__text{
    display: none;
}

#account .sidebar-hidden.account-link#side-link{
    width: 100%;
}

#account #sidebar.sidebar-hidden {
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: center;   
    position: fixed   ;
    width: inherit;
    height: inherit;
    padding-bottom: 10px;
    overflow-y: auto;
    overflow-x: hidden; 
    padding: 0 1.25em 0 0.75em;
}

@media (max-width:850px) {

    .sidebar-col-hidden#sidebar-col {
        height: auto;
    }

    .sidebar-hidden#sidebar {
        height: 100%;
        margin-top: 0.8rem;
        overflow-x: auto;
    }
}

@media (max-width:850px) and (orientation: landscape) {

    #sidebar {
        position: relative;
    }

    .sidebar-full#sidebar {
        margin-top: 3rem;
        overflow-x: scroll;
    }

    .sidebar-col-hidden#sidebar-col {
        height: auto;
        overflow-x: hidden;
        overflow-y: hidden;
    }

    .sidebar-hidden#sidebar {
        height: 100%;
        margin-top: 3rem;
        overflow-x: auto;
    }
}