.suggestion-filter {
    display: flex;
    flex-flow: row;
    align-items: stretch;
    justify-content: end;
    min-width: 180px;
    margin-right: 12px;
    margin-left: 12px;
    font-size: 12.8px;
}

.suggestion-filter-tabs {
    width: 100%;
    border: none;
}

.suggestion-filter-tabs.nav {
    margin-bottom: 2rem;
}

.suggestion-filter .suggestion-filter-tabs .suggestion-tab-item {
    width: 100%;
    margin-bottom: 4px;
    border-radius: .25rem;
    cursor: pointer;
}

#suggestion-tab-link {
    background-color: white;
    border: 2px solid white;
    border-radius: .25rem;
}

#suggestion-tab-link:hover {
    border: 2px solid var(--background-grey);
}

.active#suggestion-tab-link {
    background-color: rgba(33,37,41,.25);
    border: 1px solid var(--background-grey);
}

.suggestion-tab-header {
    background-color: transparent !important;
    border: none;
}

.active#suggestion-tab-link .suggestion-tab-header {
    color: #495057;
}

/* Scroll */
.suggestion-filter::-webkit-scrollbar {
    display: none;
}

@media (max-width: 850px) {

    .suggestion-filter {
        margin: 1rem 0 0.5rem 0;
        width: 100%;
        font-size: smaller;
    }

    .suggestion-filter-tabs.nav {
        margin-bottom: 1em;
    }

    ::placeholder {
        font-size: smaller;
    }
}