.table-alerts-head {
    text-align: center;
    background-color: var(--topbar-dark);
    color: white;
    overflow-x: auto;
}

.table-alerts-body {
    width: 100%;
    text-align: center;
    background-color: white;
    overflow-x: auto;
}

.table-alerts-body:hover {
    background-color: var(--light-green-1);
}

.alerts-list {
    display: flex;
    width: 100%;
    justify-content: center;
    overflow-x: auto;
}

.alerts-list table {
    table-layout: fixed;
}

.alerts-list table tr th:last-child {
    width: 160px;
}

.alerts-list .colspan-style {
    width: 100%;
    text-align: center;
    background-color: white;
    word-wrap: break-word;
}

@media (max-width: 850px) {

    .alerts-list .colspan-style {
        color: var(--light-green-1);
        font-weight: 700;
        text-align: end;
    }

    .alerts-list table {
        border: 0;
        width: 100%;
    }
    
    .alerts-list table caption {
        font-size: 1.3em;
    }
      
    .alerts-list table thead {
        width: 100%;
        border: none;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
    }
      
    .alerts-list table tr {
        border-bottom: 0.5em solid var(--topbar-dark);
        display: flex;
        flex-direction: column;
        width: 100%;
    }
      
    .alerts-list table td {
        border-bottom: 1px solid #ddd;
        font-size: .8em;
        text-align: right;
    }
      
    .alerts-list table td::before {
        /*
        * aria-label has no advantage, it won't be read inside a table
        content: attr(aria-label);
        */
        content: attr(data-label);
        float: left;
        font-weight: bold;
        text-transform: uppercase;
    }
      
    .alerts-list table td:last-child {
        border-bottom: 0;
    }

    .emptyrow-alerts {
        display: none;
    }

}