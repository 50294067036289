.table-manag-users {
  width: 100%;
}

.userHistory-link {
  cursor: pointer;
  text-decoration: underline;
}

.table-management-users-head {
    text-align: center;
    font-size: 13.5px;
    background-color: var(--topbar-dark);
    color: white;
}

.table-management-users-body {
    width: 100%;
    text-align: center;
    align-items: stretch;
    background-color: white;
    overflow-x: auto;
}

.table-management-users-body tr:hover {
    background-color: var(--light-green-1);
}

table.table {
    margin-bottom: 0.8rem;
}

.usersTable-title {
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--light-green-1);
    background-color: var(--topbar-dark);
    font-weight: bold;
    border: none;
    border-radius: .25rem;
    padding: 1% 1%;
    margin-bottom: 0;
    outline: none;
    transition: color .15s ease-in-out,
                background-color .15s ease-in-out;
    width: 100%;
}

.usersTable-title i {
    margin-right: 8px;
}

@media screen and (max-width: 850px) {

  .table-manag-users {
    width: 98%;
    margin-bottom: 0;
    color: #212529;
    border: 1px solid rgba(0,0,0,.125);
    overflow: visible;
    font-size: small;
  }

  .table-manag-users table {
    border: 0;
  }

  .table-manag-users table caption {
    font-size: 1.3em;
  }

  .table-manag-users table thead {
    width: 90%;
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  .table-manag-users table tr {
    border-bottom: 0.5em solid var(--topbar-dark);
    display: flex;
    flex-direction: column;
  }

  .table-manag-users table td {
    border-bottom: 1px solid #ddd;
    font-size: .8em;
    text-align: right;
  }

  .table-manag-users table td::before {
    /*
    * aria-label has no advantage, it won't be read inside a table
    content: attr(aria-label);
    */
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
  }

  .table-manag-users table td:last-child {
    border-bottom: 0;
  }

  .usersTable-title {
    margin-top: 0%;
  }
}

